import '../index.css'
import Hero from './Home/Hero'
import TopMediaFeatures from './Home/TopMediaFeatures'
import PhoneImg from './Home/PhoneImg'
import MintingMachine from './Home/MintingMachine'
import BlockChain from './Home/BlockChain'
import DApps from './Home/DApps'
import OurMission from './Home/OurMission'

export default function Home(props){
    
    return(
        <>
        {props.header}
        <Hero/>
        <TopMediaFeatures/>
        <PhoneImg/>
        <MintingMachine/>
        <BlockChain/>
        <DApps/>
        <OurMission/>
        {props.Footer}
        </>
    );
}