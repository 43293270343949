import LIC from '../images/four.jpeg'
import LIC1 from '../images/first.jpeg'
import LIC2 from '../images/eight.jpeg'
import '../index.css'
import Nft1 from '../images/Tier1.png'
import Nft2 from '../images/Tier2.png'
import Nft3 from '../images/Tier3.png'
import Nft4 from '../images/Tier4.png'

export default function Tokenomics(props) {
    return (
        <>
            {props.header}
            <div className='flex flex-wrap justify-center gap-x-[100px] gap-y-[50px] mt-[11%]'>
                <h2 className="text-[#E8D682] text-[40px] text-[900] my-3">Tokenomics</h2>
            </div>
            <div className='mt-[140px]'>
                <main className='flex flex-wrap justify-center gap-x-[100px] gap-y-[50px] mt-[11%]'>
                    <div className="block float-left w-[30%] mt-[7%]" id='token-main'
                        data-aos='fade-right' data-aos-offset='100' data-aos-delay='100'>
                        <p className="text-[white] py-[3%] text-[17px] text-[900]">
                            <b>Token Name:</b> Stardust Stroke (Gallery)<br/><br/>
                            The native token of StardustStroke (Gallery) is aptly named, embodying the essence of creativity within the digital cosmos. The choice of "StardustStroke (Gallery)" reflects our commitment to creating a celestial space for artistic expression and digital ownership.
                        </p>
                    </div>
                    <img src={LIC1} className='float-right rounded-[20px] px-[10px] w-[290px] md:w-[390] lg:w-[400px]'
                        data-aos='fade-left' data-aos-offset='100' data-aos-delay='100' alt='' />
                
                    <img src={LIC} className='float-right rounded-[20px] px-[10px] w-[290px] md:w-[390] lg:w-[400px]'
                        data-aos='fade-left' data-aos-offset='100' data-aos-delay='100' alt='' />
                    <div className="block float-left w-[30%] mt-[7%]" id='token-main'
                        data-aos='fade-right' data-aos-offset='100' data-aos-delay='100'>
                        <p className="text-[white] py-[3%] text-[17px] text-[900]">
                            <b>Token Symbol</b>: Stroke<br/><br/>
                            The token symbol, "Stroke," encapsulates the individuality and impact of every digital stroke of art within the platform. It serves as a unifying emblem, representing the unique and irreplaceable nature of the digital assets traded and owned on StardustStroke (Gallery).
                        </p>
                    </div>
                    <div className="block float-left w-[30%] mt-[7%]" id='token-main'
                        data-aos='fade-right' data-aos-offset='100' data-aos-delay='100'>
                        <p className="text-[white] py-[3%] text-[17px] text-[900]">
                            <b>Total Supply:</b> 11 Million<br/><br/>
                            <b>Decimal     :</b> 18 <br/><br/>
                            <b>Network     :</b>Polygon<br/><br/>
                        </p>
                    </div>
                    <img src={LIC2} className='float-right rounded-[20px] px-[10px] w-[290px] md:w-[390] lg:w-[400px]'
                        data-aos='fade-left' data-aos-offset='100' data-aos-delay='100' alt='' />
                    
                </main>

                <div className='flex flex-wrap justify-center gap-x-[30px] w-[100%] mt-[40px] '>
                    <h2 className='text-[white] text-[900] text-[25px] my-3 md:text-[38px] lg:text-[47px] float-left'>NFT's <span className=' text-[#E8D682]'>Distribution</span></h2>
                    <p className='text-[white] text-[900] text-[17px] float-right mx-[35px] md:mx-[5px] md:w-[50%]'>
                    The distribution plan outlines allocations for various stakeholders, including the team, community, advisors, 
                    and the broader ecosystem. This strategic distribution aims to align incentives, promote community engagement, 
                    and ensure the long-term growth and success of StardustStroke (Gallery).<br/><br/>
                    Distribution will be done in 4 phases including 11 different categories, and nft will be minted randomnly from these categories.
                    </p>
                </div>
                <main className='flex flex-wrap justify-center gap-[50px] mt-[35px]'>
                    <img src={Nft1} className='h-[260px] rounded-[10px]' data-aos='fade-down-right' data-aos-offset='100' data-aos-delay='100' alt=''/>
                    <img src={Nft2} className='h-[260px] rounded-[10px]' data-aos='fade-down-right' data-aos-offset='100' data-aos-delay='100' alt=''/>
                    <img src={Nft3} className='h-[260px] rounded-[10px]' data-aos='fade-up-left' data-aos-offset='100' data-aos-delay='100' alt=''/>
                    <img src={Nft4} className='h-[260px] rounded-[10px]' data-aos='fade-up-left' data-aos-offset='100' data-aos-delay='100' alt=''/>
                </main>

                <div className='md:flex  justify-center md:gap-x-[30px] w-[100%] mt-[80px] mb-[30px] '>

                    <h2 className='text-[white] text-center my-3 text-[900] text-[25px] md:text-[38px] lg:text-[47px]  '>Utility & <span className=' text-[#E8D682]'> Use Cases</span></h2>

                    <p className='text-[white]  text-[900] text-[17px] mx-[35px] md:mx-[5px] md:w-[50%]'>
                        Stroke goes beyond a mere transactional medium; it is a key that unlocks exclusive experiences within 
                        the StardustStroke (Gallery) ecosystem. From participating in platform governance to accessing premium content, 
                        the utility of Stroke extends far beyond traditional digital currencies, adding tangible value to the token and 
                        enhancing the overall user experience.
                        </p>
                </div>
            </div>

            {props.Footer}
        </>
    );
}