export default function ProjectOverview(props) {
    return (
        <>
            {props.header}

            <main className="mt-[140px]">
                <h4 className="text-white text-[900] text-[25px] md:text-[35px] lg:text-[45px] text-center">
                    <span className="text-[#E8D682]">Vision & </span> Mission</h4>
                <p className="text-[white] text-[900] text-[16px] text-center mt-[4%] mx-[5%] md:mx-[15%] lg:mx-[26%]">StardustStroke (Gallery) envision a digital landscape where creativity knows no bonds.
                    Our mission is to empower creators and redefine digital ownership , ensuring a seamless and secure platform for the creation , trade and interaction of NFT's.
                    We aspire to be the catalyst for a new erra of artistic expression, where every digital stroke find its rightful place in the cosmic tapestry of the digital
                    art universe.</p><br/><br/>
                <h4 className="text-white text-[900] text-[25px] md:text-[35px] lg:text-[45px] text-center">
                    <span className="text-[#E8D682]">Obje</span>ctive</h4>
                <p className="text-[white] text-[900] text-[16px] text-center mt-[4%] mx-[5%] md:mx-[15%] lg:mx-[26%]">
                    Our objectives are threefold. Firstly, we aim to facilitate the seamless creation and trade of unique digital assets, 
                    empowering creators to showcase their artistry without constraints. Secondly, we strive to foster a community-driven 
                    platform that acts as a vibrant hub for artists and collectors, promoting collaboration and mutual appreciation. 
                    Lastly, we seek to redefine the gaming and dating industries by introducing unique NFT experiences that transcend 
                    traditional boundaries and elevate digital interactions to an unprecedented level.
                    </p>
                <h2 className="text-white text-[900] text-[25px] md:text-[35px] lg:text-[45px] text-center mt-[60px]">
                    <span className="text-[#E8D682]">Target </span> Audience</h2>
                <p className="text-[white] text-[900] text-[16px] text-center mt-[4%] mx-[5%] md:mx-[15%] lg:mx-[26%]">
                    Stardust Stroke (Gallery) is tailored for visionaries in the gaming and dating industries. Our platform caters to artists, 
                    gamers, and those seeking innovative digital experiences in the realm of dating. By blending the worlds of creativity and 
                    interaction, our target audience encompasses individuals who appreciate the fusion of cutting-edge technology with limitless 
                    artistic expression. Whether you're an artist looking for a canvas or an enthusiast seeking unique digital interactions, 
                    Stardust Stroke (Gallery) is designed with you in mind.
                    </p><br/>
            </main>
            {props.Footer}
        </>
    );

}

