import '../../index.css'
import { Link } from 'react-router-dom';
import phone from '../../images/phone-img.png'



export default function PhoneImg (){
  return (
    <div className='flex items-center justify-center w-full py-10 mb-5 md:mb-10 '>
        <div data-aos='fade-right' data-aos-offset='100' data-aos-delay='100' className='flex flex-col-reverse items-center justify-between w-full gap-y-10 md:w-auto md:gap-0 md:flex-row '>
        <img src={phone} className='lg:w-[500px]  w-[400px] h-[350px] sm:h-[450px] md:h-[502px] xl:w-[598px] lg:h-[602px] xl:h-[700px]' alt="" />
        <div data-aos='fade-left' data-aos-offset='100' data-aos-delay='100' className='flex flex-col items-center  md:items-start justify-center gap-y-6 w-full  md:w-[50%]'>
            <h6><span className='text-[#E8D682]'>Stardust Stroke</span> </h6>
            <h1 className=' text-[white] text-3xl md:text-4xl xl:text-6xl font-extrabold text-gray-200 text-center '>Gallery</h1>
            <p className=' text-[14px] lg:text-[15px] xl:text-[17px]  text-[#7b8996] text-center  w-[67%] md:w-[80%] phone-paragraph'>In an era dominated by digital experiences, StardustStroke (Gallery) emerges as a beacon of innovation within the NFT realm. <br/>
            This groundbreaking project capitalizes on the transformative potential of Non-Fungible Tokens, redefining the way we perceive and interact with digital assets. <br/>
            StardustStroke (Gallery) stands at the forefront of a digital revolution, where ownership and provenance become paramount.<br/>
            By harnessing blockchain technology, we carve a space where every stroke of creativity is immortalized, fostering a dynamic ecosystem for creators and collectors alike.<br/>
            As the pulse of the digital art scene quickens, StardustStroke (Gallery) acts as a catalyst, bringing for the novel era of connection and expression. <br/>
            With a keen focus on the gaming and dating industries, our platform goes beyond traditional boundaries, introducing NFT experiences that transcend the ordinary.<br/>
            Join us in this introduction to StardustStroke (Gallery), where innovation meets imagination, and the digital canvas becomes a limitless playground for artistic expression.<br/><br/>
            Together, let’s make a stroking difference!</p>
            <Link to={'/'}>
        <button className=" px-4 text-sm rounded-3xl py-[13px] font-medium transition-all duration-300 text-black bg-[#E8D682] hover:bg-[#E8D682] ">
        Get Your NFT Now!
        </button>
        </Link>    
        </div>
        </div>
    </div>
  )
}
