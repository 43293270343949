import '../../index.css'
import { Link } from 'react-router-dom';
import banner from '../../images/categories.png'



export default function DApps (){
  return (
    <div className='flex items-center justify-center w-full px-2 py-10 mb-5 md:mb-10'>
       <div className='flex flex-col py-16 items-center border-3 rounded-3xl border-[#E8D682] justify-center  gap-y-10  lg:w-[65%]  xl:w-[70%]  '>
       <h6><span className='text-[#E8D682]'>Stardust Stroke</span> Distribution</h6>
            {/* <h1 className=' text-[white] text-[60px] sm:text-[95px] md:text-[120px] lg:text-[150px] xl:text-[200px] font-extrabold text-center '>dApps</h1> */}
            <p className=' text-[14px] lg:text-[15px] xl:text-[17px]  text-[#7b8996] text-center w-[80%] sm:w-[60%] md:w-[50%] '>All NFTs are also divided in 11 categories which can be chosen randomly.</p>
            <img data-aos='fade-left' data-aos-offset='100' data-aos-delay='100' src={banner} className='lg:w-[456px] w-[196px] h-[154px] sm:w-[336px] sm:h-[264px] xl:w-[544px] lg:h-[358px] xl:h-[428px]' alt="" />
            <Link to={'/'}>
        <button className=" px-4 text-sm rounded-3xl py-[13px] font-medium transition-all duration-300 text-black bg-[#E8D682] hover:bg-[#E8D682] ">
        Get Your NFT Now!
        </button>
        </Link>    
       </div>
    </div>
  )
}
