import '../../index.css'
import { Link } from 'react-router-dom';
import banner from '../../images/chart.jpg'



export default function BlockChain (){
  return (
    <div className='flex items-center justify-center w-full py-10 mb-5 md:mb-10 '>
        <div data-aos='fade-right' data-aos-offset='100' data-aos-delay='100' className='flex flex-col-reverse items-center justify-between w-full gap-y-10 md:w-[90%] lg:w-[82%] xl:w-[75%] md:gap-0 md:flex-row '>
        <div className='flex flex-col items-center  md:items-start justify-center gap-y-8 w-full  md:w-[50%]'>
            <h1 className=' text-[white] text-3xl md:text-4xl xl:text-6xl font-extrabold text-gray-200 text-center '>Tokenomics</h1>
            <p className=' text-[14px] lg:text-[15px] xl:text-[17px]  text-[#7b8996] text-center w-[80%] sm:w-[60%] md:w-[74%] lg:w-[66%] phone-paragraph'> 
            For the successful execution of the project, tokenomics is divided into nine division as mentioned below:<br/><br/>
            -Rewards, Bonuses, & Stakes: 40%<br/>
            -Treasury: 15%<br/>
            -Games, Dating App, Exchange, Ecosystem Growth & Auditors: 10%<br/>
            -Business Partnerships & Ventures: 10%<br/>
            -Marketing: 7%<br/>            
            -Company Possession: 7%<br/>
            -Airdrop: 5%<br/>
            -Team & Advisor: 5%<br/>
            -Charity 1%<br/>
            </p>
            <Link to={'/'}>
        <button className=" px-4 text-sm rounded-3xl py-[13px] font-medium transition-all duration-300 text-black bg-[#E8D682] hover:bg-[#E8D682] ">
        Get Your NFT Now!
        </button>
        </Link>    
        </div>
        <img data-aos='fade-left' data-aos-offset='100' data-aos-delay='100' src={banner} className='lg:w-[456px] w-[196px] h-[154px] sm:w-[336px] sm:h-[264px] xl:w-[544px] lg:h-[358px] xl:h-[428px]' alt="" />
       
        </div>
    </div>
  )
}

