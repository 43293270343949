import '../../index.css'
import { Link } from 'react-router-dom';
import img1 from '../../images/card-image1.png'
import img2 from '../../images/card-image2.png'
import img4 from '../../images/card-image4.png'
import img7 from '../../images/card-image7.png'

export default function TopMediaFeatures (){
    const cards = 
    [
        {
            id: 0,
            image: img1,
        },
        {
            id: 1,
            image: img2,
        },
        // {
        //     id: 2,
        //     image: img3,
        // },
        {
            id: 3,
            image: img4,
        },
        // {
        //     id: 4,
        //     image: img5,
        // },
        // {
        //     id: 5,
        //     image: img6,
        // },
        {
            id: 6,
            image: img7,
        },
        // {
        //     id: 7,
        //     image: img8,
        // },
        
    ]
  return (
    <div className='flex flex-col items-center justify-center w-full py-5 my-10 md:py-16 gap-y-2'>

    <h1 className=' text-[white] text-3xl md:text-4xl xl:text-6xl font-extrabold text-gray-200 text-center px-3 '> Top <span className='text-[#E8D682]'>Media</span> Features</h1>
    <div className='flex py-5 w-full  md:w-[80%] flex flex-wrap gap-4 justify-content-center '>
        {cards.map((item) =>(<div key={item.id} data-aos='fade-right' data-aos-offset='100' data-aos-delay='100' className='border-[1px] border-gray-700 w-[40%] min-h-[80px]  md:w-[220px] lg:w-[256px] p-[10px] cursor-pointer hover:border-[#E8D682] hover:bg-[#141414] transition-all duration-300 rounded-lg flex items-center justify-center  '>
            <img src={item.image}  alt="" />
        </div>
        ))}
       
    </div>
    <Link to={'/'}>
        <button className=" px-4 text-sm rounded-3xl py-[13px] font-medium transition-all duration-300 text-black bg-[#E8D682] hover:bg-[#E8D682] ">
        Get Your NFT Now!
        </button>
        </Link>    
    </div>
  )
}

