
import { Link } from 'react-router-dom';
import logo from '../images/LogoStarDustStroke.png'
import '../index.css'
import { IoIosMenu } from "react-icons/io";

import { useEffect,useState } from 'react';


export default function Header(){
  const [menuToggle, setMenuToggle] = useState(false);

  const handleMenu = () => 
  {
    setMenuToggle(!menuToggle);
  }
    return(
        <>   
    
        <div className={`${menuToggle ? 'h-[347px] ' : 'h-[110px] '} fixed z-10 overflow-hidden transition-all duration-500 bg-[#182361] w-full  top-0`}>

        <header className='container flex items-center justify-between px-14 header-container'>
                <Link to={'/'}> <img src={logo} className='h-[110px] '/> </Link>
                <div className='flex items-center text-gray-100 transition-all duration-300'>

                <ul className='items-center hidden mr-4 gap-x-8 lg:flex'>
                    <Link to={'/'}><li className='hover:text-[#E8D682]'>Home</li></Link>
                    <Link to={'/about-us'}><li className='hover:text-[#E8D682] '>About</li></Link>
                    <Link to={'/project-overview'}><li className='hover:text-[#E8D682] '>Project Overview</li></Link>
                    <Link to={'/tokenomics'}><li className='hover:text-[#E8D682] '>Tokenomics</li></Link>
                    <Link to={'/road-map'}><li className='hover:text-[#E8D682] '>Roadmap</li></Link>
                    <Link to={'/contact-us'}><li className='hover:text-[#E8D682] '>Contact</li></Link>
                </ul>
                    <Link to={'/'}><button className=" px-4 text-sm rounded-3xl py-[13px] font-medium transition-all duration-300 text-black bg-[#E8D682] hover:bg-[#E8D682] ">
                        Go To App
                        </button></Link>
                    <IoIosMenu onClick={handleMenu} size={34} className='block ml-2 cursor-pointer lg:hidden' />
                   
                </div>
                


        </header>
        <ul className={` text-gray-100 flex flex-col items-start w-full p-3 gap-y-3 border-b border-white `}>
                    <Link to={'/'} className='w-full'><li className='hover:text-[#E8D682] '>Home</li></Link>
                    <Link to={'/about-us'} className='w-full'><li className='hover:text-[#E8D682] '>About</li></Link>
                    <Link to={'/project-overview'} className='w-full'><li className='hover:text-[#E8D682] '>Project Overview</li></Link>
                    <Link to={'/tokenomics'} className='w-full'><li className='hover:text-[#E8D682] '>Tokenomics</li></Link>
                    <Link to={'/road-map'} className='w-full'><li className='hover:text-[#E8D682] '>Roadmap</li></Link>
                    <Link to={'/contact-us'} className='w-full'><li className='hover:text-[#E8D682] '>Contact</li></Link>
                    </ul>
        </div>

        </>
    );
}

