import '../../index.css'
import { Link } from 'react-router-dom';



export default function Hero(){
    
    return(
        <>
        <div className='mt-[140px] home-hero-section justify-center items-center w-full flex py-10 md:py-32 px-1 mb-5 md:mb-10'>
        <div className='flex flex-column items-center justify-center w-[100%] md:w-[80%] lg:w-[60%]  xl:w-[40%]  gap-y-6' data-aos='fade-up' data-aos-offset='100' data-aos-delay='100'>
        <h2 className=' text-[white] text-3xl md:text-4xl xl:text-6xl font-extrabold text-gray-200 text-center px-3 '> Welcome To Stardust Stroke <span className='text-[#E8D682]'>(GALLERY)</span></h2>
        <p className=' text-[14px] lg:text-[15px] xl:text-[17px]  text-[#ffffff] text-center  '><b>StardustStroke (Gallery) redefines the NFT landscape, offering a secure platform for creators and collectors. With a total token supply of 11 million Stroke tokens, our mission is to empower creators and revolutionize digital ownership.</b></p>
        <Link to={'/'}>
        <button className=" px-4  md:px-3 text-sm rounded-3xl py-2  md:py-[13px] font-medium transition-all duration-300 text-black bg-[#E8D682] hover:bg-[#E8D682] ">
        Get Your NFT Now!
        </button>
        </Link>               
        </div>
        
        </div>
            
        </>
    );
}