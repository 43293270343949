import One from '../images/first.jpeg'

export default function ContactUs(props){
    return(
        <>
        {props.header}
        <div className='mt-[140px] w-100 '>
        <main className="bg-[#070A0B] w-full h-auto flex justify-between lg:flex-row flex-col gap-y-8" id="contact-main">
                <div className='px-[10px] md:px-[50px] w-100  ' id='touch-us'>
                    <h2 className='text-[900] my-2 text-center text-[#E8D682] text-[25px] lg:text-[35px] xl:text-[40px]'>Connect with Us</h2>
                    <p className='text-[white] text-bold text-[16px] text-[serif] text-center'>Just drop an email, our support team will contact you soon.</p><br/><br/><br/>
                    <p className='text-[white] text-bold text-[12px] text-[serif] text-center'>mail to us at support@starduststroke.com</p>
                   
             <form class="max-w-lg mx-auto py-3 text-white w-100 ">
    {/* <div class="mb-2">
        <label for="name" class="block  text-sm font-bold ">Your Name</label>
        <input
            type="text"
            id="name"
            name="name"
            placeholder="Your Name"
            class="w-full px-2 text-black  text-sm lg:p-3 p-2  border border-gray-300 rounded"
        />
    </div> */}

    {/* <div class="mb-2">
        <label for="message" class="block  text-sm font-bold ">Your Message</label>
        <textarea
            id="message"
            name="message"
            rows="3"
            placeholder="Your Message"
            class="w-full px-2 text-sm p-1 text-black lg:p-3  border  rounded"
        ></textarea>
    </div> */}

    {/* <div class="mb-2">
        <input
            type="checkbox"
            id="policy"
            name="policy"
            class="mr-2"
        />
        <label for="policy" class="text-gray-700 text-sm">I agree to the privacy policy</label>
    </div> */}

    {/* <button class="w-full bg-[#E8D682] text-[#070A0B] font-semibold p-3 rounded hover:bg-blue-600 transition duration-300">
        Submit
    </button> */}
</form>
                </div>
                <img src={One} className='p-6 rounded-xl' id='contact-img' alt=''/>

            </main>
        </div>
            
            {props.Footer}
        </>
    );
}