/* eslint-disable jsx-a11y/alt-text */
import { Link } from 'react-router-dom';
import About from '../images/second.jpeg'
import Two from '../images/second.jpeg'
import Four from '../images/four.jpeg'
import Six from '../images/six.jpeg'
import Eight from '../images/eight.jpeg'


export default function AboutUs(props) {
    return (
        <>
            {props.header}
            <main className="block text-center mt-[140px] overflow-hidden ">
                <h2 className="text-[50px] text-[monospace] text-[white] ">Stardust Stroke (Gallery)</h2>
                <p className="text-[17px] text-[serif] text-[white]">
                In an era dominated by digital experiences, StardustStroke (Gallery) emerges as a beacon of innovation<br/>
                within the NFT realm. This groundbreaking project capitalizes on the transformative potential of <br/>
                Non-Fungible Tokens, redefining the way we perceive and interact with digital assets. <br/><br/>
                
                StardustStroke (Gallery) stands at the forefront of a digital revolution, where ownership and provenance <br/>
                become paramount. By harnessing blockchain technology, we carve a space where every stroke of creativity <br/>
                is immortalized, fostering a dynamic ecosystem for creators and collectors alike. As the pulse of the <br/>
                digital art scene quickens, StardustStroke (Gallery) acts as a catalyst, bringing for the novel era of <br/>
                connection and expression. With a keen focus on the gaming and dating industries, our platform goes beyond <br/>
                traditional boundaries, introducing NFT experiences that transcend the ordinary. <br/><br/>
                
                Join us in this introduction to Stardust Stroke (Gallery), where innovation meets imagination, and the <br/>
                digital canvas becomes a limitless playground for artistic expression.<br/><br/>

                Together, let’s make a stroking difference!
                </p>
                {/* <Link to={'/'}>
                    <button id="gohome" className="border-[#E8D682] py-[10px] px-[20px] bg-[#E8D682] rounded-[7px] mt-[5%] text-[white] text-[900] bg-[transparent]">Go Back Home</button>
                </Link> */}
            </main>

            <main className="md:flex justify-center items-center gap-[50px] mt-[8%]">
                <img src={About} className='mx-auto rounded-[8px] h-auto w-[370px] md:w-[400px] lg:w-[400px]' data-aos='fade-right' data-aos-offset='100' data-aos-delay='100' />
                <div className=' mt-[35px] md:w-[40%]' id='about-era'
                data-aos='fade-left' data-aos-offset='100' data-aos-delay='100'>
                    <h2 className='text-[white] px-[15px] sm:text-center md:text-start lg:text-start text-[30px] md:text-[35px] lg:text-[40px]'>Stardust Stroke<span className='text-[#E8D682]'>(Gallery)</span></h2>
                    <p className='text-[white] px-[15px]  md:mx-[1px] text-[16px] md:text-[20px] text-[700] md:max-w-[80%] mt-[1%]'>Duststroke is a dynamic and innovative company dedicated to redefining the standards of excellence in various industries. Committed to creativity and
                        cutting-edge solutions, we strive to make a lasting impact through our products and services. Our team of passionate professionals works tirelessly
                        to deliver quality and value, ensuring the success of our clients and partners.</p>
                    {/* <button id='explore-btn' className='ml-[30px] md:ml-[10px] px-[20px] py-[10px] bg-[#E8D682] rounded-[6px] mt-[40px] text-[monospace]'>Explore</button> */}
                </div>
            </main>

            <main className='mt-[105px]'>
                <h2 className=' text-[900] text-[30px] md:text-[35px] lg:text-[40px] text-white text-center'>What We have in our <span className='text-[#E8D682]'>NFT Collection</span></h2>
            </main>

            <div class="grid image-grid mt-[5%]"
            >

                <div class="grid-block">
                    <div class="tile"
                    data-aos='zoom-in-up' data-aos-offset='50' data-aos-delay='100'>
                        <a class="tile-link" href="#">
                            <img class="tile-img tile-img2" src={Four} alt="Image" />
                        </a>
                    </div>
                </div>

                <div class="grid-block">
                    <div class="tile"
                    data-aos='zoom-in-up' data-aos-offset='100' data-aos-delay='100'>
                        <a class="tile-link" href="#">
                            <img class="tile-img tile-img2" src={Two} alt="Image" />
                        </a>
                    </div>
                </div>


                <div class="grid-block">
                    <div class="tile"
                    data-aos='zoom-in-up' data-aos-offset='100' data-aos-delay='100'>
                        <a class="tile-link" href="#">
                            <img class="tile-img tile-img2" src={Eight} alt="Image" />
                        </a>
                    </div>
                </div>

                <div class="grid-block">
                    <div class="tile"
                    data-aos='zoom-in-up' data-aos-offset='100' data-aos-delay='100'>
                        <a class="tile-link" href="#">
                            <img class="tile-img tile-img1" src={Six} alt="Image" />
                        </a>
                    </div>
                </div>

            </div>
            {props.Footer}
        </>
    );
}