import '../../index.css'
import { Link } from 'react-router-dom';

export default function MintingMachine (){

  return (
    <div className='flex flex-col items-center justify-center w-full py-5 my-10 md:py-16 gap-y-6'>
        <h1 className=' text-[white] text-3xl md:text-4xl xl:text-6xl font-extrabold text-gray-200 text-left px-3 '><span className='text-[#E8D682]'>FEA</span>TURES</h1>
        <p className=' text-[14px] lg:text-[15px] xl:text-[17px]  text-[#7b8996] text-center w-[75%] md:w-[50%] '>
         To manage the Project's eco-systems, NFT will mint in 4 phases as given below:<br/><br/>
        Tier A: 0 to 5000 NFTs<br/>
        Tier B: 5001 to 15000 NFTs<br/>
        Tier C: 15001 to  30000 NFTs<br/>
        Tier D: 30001 to Unlimited NFTs <br/><br/>
        All these NFTs can be use on Gaming and Dating platform which will launch after 6th Phase. Aslo these NFts can be buy/sell on our Own Marketplace which will launch soon.
        </p>
        {/* <img src={machine} alt="" className='w-[86%] md:w-[78%] mt-8 md:mt-16 '/> */}
        <Link to={'/'}>
        <button className=" px-4 text-sm rounded-3xl py-[13px] font-medium transition-all duration-300 text-black bg-[#E8D682] hover:bg-[#E8D682] ">
        Get Your NFT Now!
        </button>
        </Link>    
    </div>
  )
}

