import React from 'react'
import logo from '../images/LogoStarDustStroke.png'
import {
  FaXTwitter,
  FaFacebook,
  FaInstagram,
  FaSquareYoutube,
  FaTelegram
} from "react-icons/fa6";
import { BsLinkedin } from "react-icons/bs";
export default function Footer() {
  return (
    <div>   
      <div>
      <img
            src={logo}
            alt='footer logo'
            className='w-[130px] top-2 mx-auto' />
      {/* <ul className='flex items-center justify-center my-4'>
            <FaXTwitter
              size={15}
              className='mr-2 hover:text-[#E8D682] hover:cursor-pointer text-[#BABDBC]' />
            <FaFacebook
              size={15}
              className='mx-2 hover:text-[#E8D682] hover:cursor-pointer text-[#BABDBC]' />
            <FaInstagram
              size={15}
              className='mx-2 hover:text-[#E8D682] hover:cursor-pointer text-[#BABDBC]' />
            <BsLinkedin
              size={15}
              className='mx-2 hover:text-[#E8D682] hover:cursor-pointer text-[#BABDBC]' />
            <FaSquareYoutube
              size={15}
              className='mx-2 hover:text-[#E8D682] hover:cursor-pointer text-[#BABDBC]' />
            <FaTelegram
              size={15}
              className='mx-2 hover:text-[#E8D682] hover:cursor-pointer text-[#BABDBC]' />
        </ul> */}
        <p className='text-[12px] text-[#BABDBC] text-center mb-[22px] mx-[18px]'>© 2023 Stardust Stroke (Gallery) | All Rights Reserved</p>
      </div>
    </div>
  )
}
